<script setup lang="ts">
import GenericError from '~/components/user-interface/generic-error.vue';

const { $authService } = useServices();

const authStore = useAuthStore();
const loading = ref<boolean>(true);

const fetchArray = [
  $authService.getRewardsConfig(),
  $authService.getRewardsSummary(),
];

const fetchRewards = () => {
  try {
    Promise.allSettled(fetchArray).finally(() => (loading.value = false));
  } catch (e) {
    console.error(e);
  }
};

onMounted(() => {
  fetchRewards();
});
</script>

<template>
  <div class="jpc-rewards bg-base-alternative py-4">
    <div
      class="text-base-priority rounded-lg p-4 pt-3 w-full max-w-[328px] mx-auto mb-4 bg-primary-layer"
    >
      <LazyUserInterfaceGenericLoader v-if="loading" />
      <div class="grid grid-cols-1 gap-4" v-else>
        <LazyUserRewardsBalance />
        <LazyUserRewardsRedeem
          v-if="!!authStore.getRewardsConfig"
          @points-redeemed="fetchRewards"
        />
        <LazyUserRewardsSummary v-if="authStore.getSummaryYearConfig.length" />
        <LazyUserRewardsAccordion v-if="!!authStore.getRewardsConfig" />
      </div>
      <GenericError
        v-if="
          !loading &&
          !authStore.getSummaryYearConfig.length &&
          !authStore.getRewardsConfig
        "
      >
        {{ $t('rewards-error') }}
      </GenericError>
    </div>
  </div>
</template>
<style scoped lang="scss"></style>
